/**
 * @module New Relic
 * @description Our New Relic Snippet
 */
import Script from 'next/script';
import { getAsset } from '@/js/utils/helpers';

/**
 * @function NewRelic
 * @description New Relic Snippet
 */
const NewRelic = () => (
  <Script
    src={ `${ getAsset( '/js/new-relic.js' ) }` }
    strategy="afterInteractive"
  />
);

export default NewRelic;
