/**
 * @module Annotation
 * @description Legal Annotation
 */
import { useState, useEffect, useRef } from 'react';

/**
 * @function Component
 * @description
 */
const Component = ( { hash, content, disclosures } ) => {
  const [ annotationIndex, setAnnotationIndex ] = useState();

  const renderedHashRef = useRef( '' );

  /**
   * @function useEffect
   * @description Kick off this modules functions
   */
  useEffect( () => {
    const legal = [ ...disclosures ].filter( ( disclosure ) => {
      if ( disclosure.hash === hash ) {
        renderedHashRef.current = disclosure.hash;
        return true;
      } if ( disclosure.alternateHash?.includes( hash ) ) {
        renderedHashRef.current = disclosure.hash;
        return true;
      }
      return false;
    } )[ 0 ];

    if ( ! legal ) {
      return;
    }

    setAnnotationIndex( legal.annotationIndex );
  }, [ disclosures, hash ] );

  return (
    <>
      { content }
      <a
        className="legal-annotation"
        href="#site-legal"
        data-legal-hash={ renderedHashRef.current }
        aria-describedby={ `legal-${ annotationIndex }` }>
          <span className="screen-reader-text">Legal Annotation</span> { annotationIndex }
      </a>
    </>
  );
};

export default Component;
