/**
 * @module Footer
 * @description Site Footer
 */
import Link from 'next/link';
import PropTypes from 'prop-types';
import SiteLegal from '@/providers/content-provider/components/SiteLegal';

/**
 * @function Footer
 * @description Default Footer
 */
const Footer = ( { ...props } ) => {
  const {
    links, footerLinks, socialLinks
  } = props;

  return <>
    <footer id="colophon" className="site-footer footer-region u-theme-light" role="contentinfo">
      <div className="site-footer-primary">
        <nav className="menu footer-menu footer-menu-primary" aria-label="Footer Primary Menu">
          <div className="l-container footer-menu-links">
            { links.map( ( link, index ) => (
              <Link href={ link.href } key={ index } className="link-title">
                { link.title }
              </Link>
            ) ) }
          </div>
        </nav>
      </div>{ /* .site-footer-primary */ }

      <div className="site-footer-secondary">
        <SiteLegal />
        <p className="site-copyright">&copy; { new Date().getFullYear() } Frontier Communications Parent, Inc. All rights reserved.</p>

        <nav className="footer-menu footer-menu-secondary" aria-label="Footer Secondary Menu">
          <ul className="menu">
            { footerLinks.map( ( link, index ) => (
                <li key={ index }><Link href={ link.href } target={ link.target }>{ link.title }</Link></li>
            ) ) }

            { /* Launches cookie consent pop-up (Needs trailing space to avoid hydration error) */ }
            <li id="ot-sdk-btn" className="ot-sdk-show-settings">Do Not Sell or Share My Personal Information </li> { /* eslint-disable-line no-irregular-whitespace */ }
          </ul>
        </nav>

        <nav className="social" aria-label="Social Menu">
          <ul className="menu social-menu">
            { socialLinks.map( ( link, index ) => (
                <li key={ index }><Link
                  href={ link.href }
                  target="_blank"
                  className={ `icon ${ link.icon }` }
                  title={ link.title }>{ link.title }</Link></li>
            ) ) }
          </ul>
        </nav>{ /* <!-- .social --> */ }
      </div>{ /* .site-footer-secondary */ }
    </footer>{ /* <!-- .site-footer --> */ }
  </>;
};

Footer.defaultProps = {
  links: [
    {
      title: 'Plans & Pricing',
      href: '/plans-pricing'
    },
    {
      title: 'Customer Support',
      href: 'https://frontier.com/helpcenter'
    },
    {
      title: 'Online Bill Pay',
      href: 'https://frontier.com/helpcenter/categories/billing'
    },
    {
      title: 'Business',
      href: 'https://business.frontier.com/'
    },
    {
      title: 'Manage Account',
      href: 'https://frontier.com/login'
    },
    {
      title: 'Order Online',
      href: '/buy'
    }
  ],
  footerLinks: [
    { href: 'https://frontier.com/corporate/privacy-policy', title: 'Privacy Policy' },
    { href: '/terms', title: 'Terms & Conditions' },
    { href: 'https://frontier.com', title: 'Accessibility' },
    { href: 'https://frontier.com/corporate/privacy-policy-california', title: 'California Privacy Policy' },
    { href: 'https://frontier.com/corporate/privacy-policy-connecticut-texas', title: 'CT, TX Privacy Policy' }
  ],
  socialLinks: [
    { icon: 'icon-twitter-x', href: 'https://x.com/FrontierCorp', title: 'X' },
    { icon: 'icon-facebook', href: 'https://www.facebook.com/FrontierInternet', title: 'Facebook' },
    { icon: 'icon-linkedin', href: 'https://www.linkedin.com/company/163906', title: 'LinkedIn' },
    { icon: 'icon-youtube', href: 'https://www.youtube.com/user/FrontierCorp', title: 'YouTube' },
    { icon: 'icon-instagram', href: 'https://www.instagram.com/frontierinternet', title: 'Instagram' }
  ]
};

Footer.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape( {
      title: PropTypes.string,
      links: PropTypes.arrayOf(
        PropTypes.shape( {
          href: PropTypes.string,
          title: PropTypes.string,
          target: PropTypes.string
        } )
      )
    } )
  ),
  footerLinks: PropTypes.arrayOf(
    PropTypes.shape( {
      href: PropTypes.string,
      title: PropTypes.string,
      target: PropTypes.string
    } )
  ),
  socialLinks: PropTypes.arrayOf(
    PropTypes.shape( {
      icon: PropTypes.string,
      href: PropTypes.string,
      title: PropTypes.string
    } )
  )
};

export default Footer;
