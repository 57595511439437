/**
 * @module Site Navigation
 * @description
 */
import hoverIntent from 'hoverintent';
import state from '@/js/config/state';

const el = {
  header: null,
  navItems: null,
  headerItems: null,
  search: null,
  activeSubmenu: null,
  navCollapse: null,
  userNavCollapse: null,

  // Buttons
  navToggle: null,
  userToggleOpen: null,
  userToggleClose: null,

  // Menus
  siteMenu: null,
  headerMenu: null,
  complementaryMenu: null,

  // State
  navOpen: false,
  userNavOpen: false
};

/**
 * @function Site Navigation
 * @description
 */
export const useSiteNavigation = ( ) => {
  /**
   * @function trapUserMenuFocus
   * @description Trap User Menu Focus
   */
  const trapUserMenuFocus = ( e ) => {
    if ( state.is_desktop ) {
      return;
    }

    const { target } = e;

    if ( target.classList.contains( 'navigation-toggle--user-close' ) ) {
      el.userNavCollapse.querySelector( 'a' ).focus();
      return;
    }

    el.userToggleClose.focus();
  };

  /**
   * @function trapSubmenuFocus
   * @description Trap Submenu Focus
   */
  const trapSubmenuFocus = () => {
    if ( state.is_desktop ) {
      return;
    }

    el.navToggle.focus();
  };

  /**
   * @function closeSiblingMenus
   * @description Close sibling menus
   */
  const closeSiblingMenus = ( id, siblings ) => {
    const activeSiblings = [ ...siblings ].filter( ( sibling ) => sibling.classList.contains( 'menu-item-has-children' ) && id !== sibling.querySelector( 'button' ).getAttribute( 'id' ) );

    activeSiblings.forEach( ( active ) => {
      active.classList.remove( 'menu-item--active' );
      const btn = active.querySelector( 'button' );
      btn.setAttribute( 'aria-expanded', false );
      const submenu = active.querySelector( '.submenu-wrap' );

      if ( ! submenu ) {
        return;
      }

      submenu.classList.remove( '--active' );
    } );
  };

  /**
   * @function toggleSubmenu
   * @description Toggle our submenu
   */
  const toggleSubmenu = ( target, submenu ) => {
    if ( ! submenu ) {
      return;
    }

    const { parentNode } = target;
    const id = target.getAttribute( 'id' );
    const siblings = parentNode.parentNode.querySelectorAll( '.menu-item' );
    closeSiblingMenus( id, siblings );

    if ( target.getAttribute( 'aria-expanded' ) === 'false' ) {
      parentNode.classList.add( 'menu-item--active' );
      target.setAttribute( 'aria-expanded', true );
      submenu.classList.add( '--active' );

      return;
    }

    parentNode.classList.remove( 'menu-item--active' );
    target.setAttribute( 'aria-expanded', false );
    submenu.classList.remove( '--active' );
  };

  /**
   * @function openSubmenu
   * @description Open Submenu
   */
  const openSubmenu = ( e ) => {
    // Ignore mobile hover events
    if ( e.type === 'mouseover' && state.is_mobile ) {
      return;
    }

    const { target } = e;
    const { parentNode } = target;
    const submenu = parentNode.querySelector( '.submenu-wrap' );

    if ( e.type === 'mouseover' && el.activeSubmenu ) {
      const btnId = target.getAttribute( 'id' );
      const submenuId = el.activeSubmenu.getAttribute( 'aria-labelledby' );

      if ( btnId === submenuId ) {
        return;
      }
    }

    el.activeSubmenu = submenu;

    toggleSubmenu( target, submenu );

    // All we need for desktop is done
    if ( state.is_desktop ) {
      return;
    }

    const firstLink = el.activeSubmenu.querySelector( 'a' );

    // We need to wait for submenu to be visible
    setTimeout( () => {
      firstLink.focus();
    }, 100 );
  };

  /**
   * @function closeSubmenu
   * @description Close Submenu
   * @param target - Should be the top-level button node
   */
  const closeSubmenu = ( e ) => {
    // Ignore mobile hover events
    if ( ( e.type === 'mouseout' && state.is_mobile ) || ( e.type === 'mouseout' && state.is_tablet ) ) {
      return;
    }

    const { target } = e;

    // Fix issue with hover over anchor tags, set target manually.
    // if ( target.tagName === 'A' && state.is_desktop ) {
    //   target = el.header.querySelector( '.menu-item--active button' );
    // }

    const parentNode = target.closest( '.menu-item' );

    if ( ! parentNode ) {
      return;
    }

    const submenu = parentNode.querySelector( '.submenu-wrap' );

    el.activeSubmenu = null;
    parentNode.classList.remove( 'menu-item--active' );

    if ( ! submenu ) {
      return;
    }

    submenu.classList.remove( '--active' );
    target.setAttribute( 'aria-expanded', false );
  };

  /**
   * @function closeUserNav
   * @description Close User Nav
   */
  const closeUserNav = () => {
    el.userNavOpen = false;
    document.documentElement.setAttribute( 'data-nav-open', false );
    el.header.classList.remove( 'navigation-open' );
    el.userNavCollapse.classList.remove( 'navigation-open' );

    el.userMenu.querySelector( 'button' ).click();
  };

  /**
   * @function openNav
   * @description Open Nav
   */
  const openNav = () => {
    if ( el.userNavOpen ) {
      closeUserNav();
    }

    el.navOpen = true;
    document.documentElement.setAttribute( 'data-nav-open', true );
    el.navToggle.classList.add( 'navigation-open' );
    el.header.classList.add( 'navigation-open' );
    el.navCollapse.classList.add( 'navigation-open' );

    const lastChild = el.header.querySelector( '.site-menu > li:last-of-type > *' );
    lastChild.addEventListener( 'blur', trapSubmenuFocus.bind( this ) );
  };

  /**
   * @function closeNav
   * @description Close Nav
   */
  const closeNav = () => {
    el.navOpen = false;
    document.documentElement.setAttribute( 'data-nav-open', false );
    el.navToggle.classList.remove( 'navigation-open' );
    el.header.classList.remove( 'navigation-open' );
    el.navCollapse.classList.remove( 'navigation-open' );
  };

  /**
   * @function openUserNav
   * @description Open User Nav
   */
  const openUserNav = () => {
    if ( el.navOpen ) {
      closeNav();
    }

    el.userNavOpen = true;
    document.documentElement.setAttribute( 'data-nav-open', true );
    el.header.classList.add( 'navigation-open' );
    el.userNavCollapse.classList.add( 'navigation-open' );
    el.userMenu.querySelector( 'a' ).focus();

    const lastChild = el.header.querySelector( '.user-menu .submenu > li:last-of-type > *' );
    lastChild.addEventListener( 'blur', trapUserMenuFocus.bind( this ) );
  };

  /**
   * @function navToggle
   * @description Open / Close Nav
   */
  const navToggle = () => {
    if ( ! el.navOpen ) {
      openNav();
      return;
    }

    closeNav();
  };

  /**
   * @function determineClose
   * @description Allow user to close nav by clicking outside of it
   */
  const determineClose = ( e ) => {
    if ( state.is_desktop ) {
      return;
    }

    const { target } = e;

    if ( target.tagName === 'HEADER' ) {
      if ( el.navOpen ) {
        closeNav();
      }

      if ( el.userNavOpen ) {
        closeUserNav();
      }
    }
  };

  /**
   * @function setA11y
   * @description
   */
  const setA11y = ( element, index, prefix ) => {
    const { parentNode } = element;

    element.setAttribute( 'id', `${ prefix }-menu-item-${ index }` );
    element.setAttribute( 'aria-haspopup', true );
    element.setAttribute( 'aria-controls', `${ prefix }-menu-submenu-${ index }` );
    element.setAttribute( 'aria-expanded', false );

    const submenu = parentNode.querySelector( '.submenu-wrap' );

    if ( ! submenu ) {
      return;
    }

    submenu.setAttribute( 'role', 'menu' );
    submenu.setAttribute( 'id', `${ prefix }-menu-submenu-${ index }` );
    submenu.setAttribute( 'aria-labelledby', `${ prefix }-menu-item-${ index }` );
  };

  /**
   * @function bindEvents
   * @description Bind events for this modules functions
   */
  const bindEvents = () => {
    el.navToggle.addEventListener( 'click', navToggle.bind( this ) );
    el.header.addEventListener( 'click', determineClose.bind( this ) );

    if ( el.userToggleOpen ) {
      el.userToggleOpen.addEventListener( 'click', openUserNav.bind( this ) );
    }

    if ( el.userToggleClose ) {
      el.userToggleClose.addEventListener( 'click', closeUserNav.bind( this ) );
      el.userToggleClose.addEventListener( 'blur', trapUserMenuFocus.bind( this ) );
    }
  };

  /**
   * @function init
   * @description Kick off this modules functions
   */
  const init = () => {
    el.header = document.querySelector( '.site-header' );

    if ( ! el.header ) {
      return;
    }

    // Buttons
    el.navToggle = el.header.querySelector( '.navigation-toggle' );
    el.userToggleOpen = el.header.querySelector( '.navigation-toggle--user-open' );
    el.userToggleClose = el.header.querySelector( '.navigation-toggle--user-close' );

    // Elements
    el.navCollapse = el.header.querySelector( '.navigation-collapse' );
    el.userNavCollapse = el.header.querySelector( '.navigation-collapse--secondary' );

    // Search
    el.search = el.header.querySelector( '.search-form' );

    // Menus
    el.siteMenu = el.header.querySelector( '.site-menu' );
    el.userMenu = el.header.querySelector( '.user-menu' );
    el.headerMenu = el.header.querySelector( '.header-menu' );
    el.complementaryMenu = el.header.querySelector( '.complementary-menu' );

    // Menu Items + Submenus
    el.navItems = el.header.querySelectorAll( '.site-menu > li.menu-item-has-children > button' );
    el.navItems.forEach( ( item, index ) => {
      const { parentNode } = item;
      hoverIntent( parentNode, openSubmenu.bind( this ), closeSubmenu.bind( this ) );
      setA11y( item, index, 'site' );
      item.addEventListener( 'click', openSubmenu.bind( this ) );
    } );

    // User Menu
    el.headerItems = el.header.querySelectorAll( '.header-menu > li.menu-item-has-children > button' );
    el.headerItems.forEach( ( item, index ) => {
      const { parentNode } = item;
      hoverIntent( parentNode, openSubmenu.bind( this ), closeSubmenu.bind( this ) );
      setA11y( item, index, 'header' );
      item.addEventListener( 'click', openSubmenu.bind( this ) );
    } );

    // Bind our buttons / elements with events
    bindEvents();
  };

  return { init };
};

export default useSiteNavigation;
