/**
 * @module Countdown Timer
 * @description
 */

/**
 * @function countdown
 * @description Given an end date, pass the time remaining to the provided callback
 */
export const countdown = ( endDate, callback = () => { } ) => {
  const countdownTimer = setInterval( () => {
    const now = new Date().getTime();
    const timeLeft = endDate - now;

    if ( timeLeft < 0 ) {
      callback( {
        hours: 0,
        minutes: 0,
        seconds: 0
      } );
      clearInterval( countdownTimer );
      return;
    }

    const hours = Math.floor( ( timeLeft % ( 1000 * 60 * 60 * 24 ) ) / ( 1000 * 60 * 60 ) );
    const minutes = Math.floor( ( timeLeft % ( 1000 * 60 * 60 ) ) / ( 1000 * 60 ) );
    const seconds = Math.floor( ( timeLeft % ( 1000 * 60 ) ) / 1000 );

    callback( {
      hours,
      minutes,
      seconds
    } );
  }, 1000 );
};

export default countdown;
